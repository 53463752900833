import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

export default makeStyles<Theme, { color: string; textColor?: string; tableCell: boolean }>(
  (theme: Theme) => {
    return createStyles({
      root: {
        display: 'flex',
        flexDirection: 'row',
        paddingBottom: '0.8rem'
      },
      eventSquare: ({ color, tableCell }) => ({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: color,
        height: '90px',
        minWidth: tableCell ? 'initial' : '103px',
        width: tableCell ? '70px' : 'auto',
        paddingTop: '10px',
        paddingBottom: '3px',
        marginLeft: tableCell ? '0.5rem' : ''
      }),
      descriptionContainer: {
        paddingLeft: '0.5rem',
        paddingTop: '0.1rem'
      },
      descriptionTitle: {
        fontWeight: theme.typography.fontWeightBold,
        color: theme.palette.common.black,
        fontSize: theme.typography.body2.fontSize
      } as CSSProperties,
      descriptionDescription: {
        color: theme.palette.common.black,
        fontSize: theme.typography.body2.fontSize,
        overflowWrap: 'break-word'
      },
      day: ({ textColor, tableCell }) => ({
        fontSize: theme.typography.h4.fontSize,
        paddingBottom: 0,
        marginBottom: 0,
        color: textColor ? textColor : theme.palette.common.white,
        lineHeight: 1,
        fontWeight: tableCell ? theme.typography.fontWeightMedium : ('initial' as any)
      }),
      month: ({ textColor }) => ({
        fontWeight: theme.typography.fontWeightMedium as any,
        color: textColor ? textColor : theme.palette.common.white
      }),
      time: {
        color: theme.palette.common.white
      }
    });
  }
);
