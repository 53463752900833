import useT from 'hooks/useT';
import { useContext } from 'react';
import { logout } from 'utils/utils';
import { AppContext } from 'context/App/AppContext';
import { UserMenu } from '@groupbuilderoy/gb-components-library';
import StartParameter from 'utils/startParameters';
import { useEditingMode } from 'hooks/useEditingMode';

export default function User() {
  const t = useT();
  const { appState } = useContext(AppContext);
  const { editingModeAvailable, editingModeEnabled, toggleEditingMode } = useEditingMode();

  const handleLogout = () => {
    logout(appState.startParameters.organisationId);
  };

  const handleToggleEditingMode = () => {
    sessionStorage.setItem(StartParameter.editingMode, String(!editingModeEnabled));
    toggleEditingMode();
  };

  const links = [{ text: t('user.logout'), onClick: handleLogout }];
  if (editingModeAvailable) {
    links.push({
      text: t('user.editingMode', { state: editingModeEnabled ? t('state.on') : t('state.off') }),
      onClick: handleToggleEditingMode
    });
  }

  return (
    <>
      <p>{editingModeAvailable}</p>
      <UserMenu data-testid='user-menu' name={appState.user?.name} links={links} />
    </>
  );
}
