import { AxiosResponse } from 'axios';
import { instance } from 'axiosInstances';
import UserRights from 'context/App/UserRights';
import { BaseResponse } from './types';
import { createConfig, createPrivatePath } from './utils';

export interface LoginResponse {
  data: {
    userID: number;
    name: string;
    userLevel: number;
    canUpload: boolean;
    email: string;
  };
  error: null | string;
  token: string;
}

export const login = (
  username: string,
  password: string,
  tenantId: string
): Promise<AxiosResponse<LoginResponse>> =>
  instance.post(`/v1/public/accounts/login`, {
    username,
    password,
    schema: tenantId
  });

export interface TokenExchangeResponse {
  token: string;
  user: {
    email: string;
    name: string;
    userId: number;
    userLevel: number;
    auth0Id: string;
  };
}

export const tokenExchange = async (
  organisationId: string,
  tenantId: string,
  accessToken: string
): Promise<TokenExchangeResponse> => {
  try {
    const { data, error } = (await instance.get(
      `/v6/public/organisations/${organisationId}/tenants/${tenantId}/token-exchange`,
      createConfig({ token: accessToken })
    )) as any;

    if (error || !data.data.token || !data.data.user) throw new Error('Failed to login');

    const token = data.data.token as string;
    const user = data.data.user;

    return { token, user };
  } catch (e) {
    throw e;
  }
};

export const fetchUserRights = async ({
  token,
  organisationId,
  tenantId,
  projectId
}: any): Promise<UserRights> => {
  try {
    const { error, data } = (await instance.get(
      createPrivatePath({ organisationId, tenantId, projectId }) + '/user-rights',
      createConfig({ token })
    )) as BaseResponse;

    if (error || !data.data?.userRights) throw new Error('Unable to fetch user rights!');

    return new UserRights(data.data.userRights);
  } catch (e) {
    throw e;
  }
};

export const getProjectsWithApartments = async (token: string) => {
  try {
    const response = (await instance.get(
      createPrivatePath({}, 'v2') + '/projects/with-apartments',
      createConfig({ token })
    )) as BaseResponse;

    if (response.error || !response.data)
      throw new Error('Invalid response for projects and apartments');

    return response.data;
  } catch (e: any) {
    throw e;
  }
};
